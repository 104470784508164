import styled from 'styled-components'
import { DEVICE } from '../../../constants'

const MarginMobile = styled.div`
  @media ${DEVICE.phone} {
    margin: ${(props) => (props.margin ? props.margin : '1em')};
  }
`
const MarginBotton = styled.div`
  margin-bottom: ${(props) => (props.margin ? props.margin : '1em')};
`

const MarginTop = styled.div`
  margin-top: ${(props) => (props.margin ? props.margin : '1em')};
`

const MarginLeft = styled.div`
  margin-left: ${(props) => (props.margin ? props.margin : '1em')};
`

const MarginRight = styled.div`
  margin-right: ${(props) => (props.margin ? props.margin : '1em')};
`

const Margin = styled.div`
  margin: ${(props) => (props.margin ? props.margin : '1em')};
`

export {
  MarginMobile,
  MarginBotton,
  MarginTop,
  MarginLeft,
  MarginRight,
  Margin
}
