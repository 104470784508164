import React from "react";
import { InputContainer } from "./styles";

const SegfyInputWrapper = (props) => {
  return (
    <InputContainer {...props}>
      <label>{props.label} {props.required ? '*' : ''}</label>
      {props.children}
    </InputContainer>
  );
};

export { SegfyInputWrapper };
