import React from 'react'
import ReactModal from 'react-modal'
import { Flex } from '../Layout/Flex'
import { MarginBotton } from '../Layout/Margin'
import IconButton from '../IconButton'
import { Heading3 } from '../Typography'

const contentDefault = {
  border: '1px solid transparent',
  borderRadius: '5px',
  backgroundColor: '#ffffff',
  outline: '0',
  margin: 'auto',
  position: 'absolute',
  top: 0,
  left: 0,
  bottom: 0,
  right: 0
}

const overlay = {
  backgroundColor: 'rgba(125, 121, 115, 0.75)',
  position: 'fixed',
  top: '0px',
  left: '0px',
  right: '0px',
  bottom: '0px'
}

const defaultModalStyles = {
  content: {
    ...contentDefault,
    padding: '1em'
  },
  overlay
}

const miniModalStyles = {
  content: {
    ...contentDefault,
    width: '350px',
    height: '180px',
    margin: 'auto',
    position: 'absolute',
    top: 0,
    left: 0,
    bottom: 0,
    right: 0
  },
  overlay
}

const smallModalStyles = {
  content: {
    ...contentDefault,
    width: '560px',
    height: '310px'
  },
  overlay
}

const midModalStyles = {
  content: {
    ...contentDefault,
    width: '650px',
    height: '510px'
  },
  overlay
}

const largeModalStyles = {
  content: {
    ...contentDefault,
    width: '1000px',
    height: '520px'
  },
  overlay
}

const extraLargeModalStyles = {
  content: {
    ...contentDefault,
    width: '1140px',
    height: '575px'
  },
  overlay
}

const setModalStyle = (props) => {
  if (props && props.size) {
    switch (props.size) {
      case 'xsm':
        return miniModalStyles
      case 'sm':
        return smallModalStyles
      case 'm':
        return midModalStyles
      case 'lg':
        return largeModalStyles
      case 'xlg':
        return extraLargeModalStyles
      default:
        return defaultModalStyles
    }
  } else return defaultModalStyles
}

const setHollowModalStyle = (style) => {
  return {
    content: {
      ...style.content,
      padding: '0'
    },
    overlay: style.overlay
  }
};

const Modal = (props) => {
  //ReactModal.setAppElement('#root')
  return (
    <ReactModal
      style={setModalStyle(props)}
      shouldCloseOnOverlayClick={props.shouldCloseOnOverlayClick}
      onRequestClose={props.handleCloseModal}
      isOpen={props.isOpen}
      size={props.size ? props.size : 'md'}
    >
      <MarginBotton>
        <Flex space_between>
          <Heading3 color='color_neutral_darkest'>
            {props.title ? props.title : ''}
          </Heading3>
          <Flex align_right>
            <IconButton
              width={15}
              height={15}
              icon='cancel'
              icon_size={9}
              normal
              onClick={props.onClose}
            ></IconButton>
          </Flex>
        </Flex>
      </MarginBotton>

      {props.children}
    </ReactModal>
  )
}

const HollowModal = (props) => {
  return (
    <ReactModal
      style={setHollowModalStyle(setModalStyle(props))}
      shouldCloseOnOverlayClick={props.shouldCloseOnOverlayClick}
      onRequestClose={props.handleCloseModal}
      isOpen={props.isOpen}
      size={props.size ? props.size : 'md'}
    >
      {props.children}
    </ReactModal>
  )
}

export { Modal, HollowModal }
