import styled from "styled-components";

const StarCheckboxContainer = styled.span`
padding-left: 6px;
> label {
  margin-left: 5px;
      font-size: 10pt
    }
  > input {
    visibility: hidden;
    font-size: 16pt;
    cursor: pointer;

    &:disabled {
      cursor: not-allowed;
    }

    &:before {
      color: #f39c12;
      content: "☆";
      margin-top: -8px;
      position: absolute;
      visibility: visible;
    }
    &:disabled:before {
      color: #ddd;
    }
    &:checked:before {
      content: "★";
      margin-top: -8px;
      position: absolute;
    }

    
  }
`;

export { StarCheckboxContainer };
