import React from 'react'
import PropTypes from 'prop-types'
import FontIcon from '../FontIcon'
import { Button, Link } from './styles'

const LinkButton = (props) => {
  return (
    <Button type='button' label={props.label} {...props}>
      <FontIcon icon={props.icon} size={props.size} />
      <Link>{props.label}</Link>
    </Button>
  )
}

export default LinkButton

LinkButton.propTypes = {
  icon: PropTypes.string,
  label: PropTypes.string.isRequired,
  size: PropTypes.number,
  margin: PropTypes.number,
  vertical: PropTypes.string,
  disabled: PropTypes.string
}

LinkButton.defaultProps = {
  icon: 'barchart'
}
