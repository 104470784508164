import styled from 'styled-components';
import { GridBase } from '../../shared-styles';
import { COLORS } from "../../constants";

export const DatePickerContainer = styled(GridBase)`
  & .datepicker-container {
      border: 1px solid ${COLORS.color_neutral_medium};
    background-color: ${COLORS.color_lightest};
    box-shadow: 0px 1px 3px #00000029;
    border-radius: 5px;
    width: 310px;
    height: 330px;
    z-index: 9999
  }

  & .react-datepicker__header {
    margin-top: 5px;
    background-color: unset;
  }

  & .react-datepicker {
    width: 100%;
    text-align: center;
    border: unset;
  }

  & .react-datepicker__day-name,
  & .react-datepicker__day,
  & .react-datepicker__time-name {
    display: inline-block;
    line-height: 2.25rem;
    width: 2.25rem;
    margin: 0.166rem;
    text-align: center;
    color: ${COLORS.color_darkest};
    font-weight: 600;
    font-size: 11px;
  }

  & .react-datepicker__day-name,
  & .react-datepicker__current-month {
    font-weight: bold;
    font-size: 11px;
    color: ${COLORS.color_darkest};
  }

  & .react-datepicker__day--weekend {
    color: ${COLORS.color_neutral_medium};
    font-weight: 500;
  }

  & .react-datepicker__day--outside-month {
    color: ${COLORS.color_lightest};
    font-weight: 500;
  }

  & .react-datepicker__day:focus {
    outline: 0;
  }

  & .react-datepicker__day--keyboard-selected,
  & .react-datepicker__day--selected {
    background-color: ${COLORS.color_brand_primary_medium};
    color: white;
    font-weight: bold;
    border-radius: 20px;
    outline: 0;
  }

  & .react-datepicker__day--keyboard-selected {
    background-color: ${COLORS.color_brand_primary_medium};
  }

  & .react-datepicker__day--selected:hover {
    background-color: ${COLORS.color_lightest};
  }

  & .react-datepicker__navigation {
    background: none;
    line-height: 1.7rem;
    text-align: center;
    cursor: pointer;
    position: absolute;
    top: 15px;
    width: 0;
    padding: 0;
    border: 0.45rem solid transparent;
    z-index: 1;
    height: 10px;
    width: 10px;
    text-indent: -999em;
    overflow: hidden;
  }

  & .react-datepicker__navigation--previous {
    left: 10px;
    border-right-color: #ccc;
  }

  & .react-datepicker__navigation--next {
    right: 10px;
    border-left-color: #ccc;
  }

  & input.has-error {
    border-color: red;
  }
`;
