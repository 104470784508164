/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import styled, { css } from 'styled-components';
import { COLORS } from '../constants';

const TextFieldCss = `
border-radius: 15px;
padding: 0 12px;
border: 1px solid ${COLORS.color_neutral_medium};
height: 30px;
min-width: 200px;
::placeholder {
  color: ${COLORS.color_neutral_dark};
}
:focus {
  border: 1px solid ${COLORS.color_neutral_darkest};
  transition: ease-in-out, width 0.35s ease-in-out;
  outline: none
}
`;

const TextField = styled.input`
  ${TextFieldCss}
`;

export { TextField, TextFieldCss };
