import React from "react";
import BounceLoader from "react-spinners/BounceLoader";
import { COLORS } from "../../constants";
import { Container, SpinnerContainer } from "./styles";

const methods = {
  show: () => {},
  hide: () => {},
};

const Spinner = () => {
  const [visible, setVisible] = React.useState(false);

  function showIt() {
    setVisible(true);
  }
  function hideIt() {
    setVisible(false);
  }

  const showRef = React.useRef(showIt);
  const hideRef = React.useRef(hideIt);

  React.useEffect(() => {
    methods.show = showRef.current;
    methods.hide = hideRef.current;
  }, []);

  if (!visible) return <React.Fragment></React.Fragment>;

  return (
    <Container>
      <SpinnerContainer>
        <BounceLoader
          size={100}
          color={COLORS.color_brand_primary_medium}
          loading={visible}
        />
      </SpinnerContainer>
    </Container>
  );
};

export { Spinner, methods };
