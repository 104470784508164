import styled, { css } from 'styled-components'
import { COLORS, BUTTON_SIZES, DEVICE } from '../constants'

const Button = styled.button`
  cursor: pointer;
  border-radius: 15px;

  width: ${BUTTON_SIZES.default.width};
  height: ${BUTTON_SIZES.default.height};

  ${(props) =>
    props.medium &&
    css`
      width: ${BUTTON_SIZES.medium.width};
      height: ${BUTTON_SIZES.medium.height};
    `};

  ${(props) =>
    props.small &&
    css`
      width: ${BUTTON_SIZES.small.width};
      height: ${BUTTON_SIZES.small.height};
    `};

    ${(props) =>
      props.xsmall &&
      css`
        width: ${BUTTON_SIZES.xsmall.width};
        height: ${BUTTON_SIZES.xsmall.height};
      `};

  ${(props) =>
    props.margin &&
    css`
      margin: ${props.margin};
    `};

  transition-duration: 0.2s;
  border: 1px solid ${COLORS.color_brand_primary_medium};

  color: ${COLORS.color_brand_primary_medium};

  font-size: 12px;

  @media ${DEVICE.phone} {
    font-size: 12.1px;
  }

  min-width: 30px;
  margin-left: 5px;
  margin-right: 5px;
  outline: 0px;

  &:hover {
    background-color: ${(props) =>
      props.green
        ? COLORS.color_green_lightest
        : props.disabled
        ? COLORS.color_neutral_medium
        : props.primary
        ? COLORS.color_brand_primary_light
        : COLORS.color_brand_primary_ultra_lightest};
  }

  background-color: ${COLORS.color_lightest};

  ${(props) =>
    props.primary &&
    css`
      background-color: ${COLORS.color_danger};
      color: ${COLORS.color_lightest} !important;
      border: none;
    `};

  ${(props) =>
    props.green &&
    css`
      background-color: ${COLORS.color_green};
      color: ${COLORS.color_lightest};
      border: none;
    `};

  ${(props) =>
    props.disabled &&
    css`
      background-color: ${COLORS.color_neutral_medium};
      color: ${COLORS.color_neutral_dark};
      border: none;
      cursor: not-allowed
    `};
`

export default Button
