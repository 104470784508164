import styled from 'styled-components';

const GridBase = styled.div`
  display: grid;
`;

const FlexBase = styled.div`
  display: flex;
`;

export { GridBase, FlexBase };