import styled from "styled-components";
import { COLORS } from "../constants";

const ErrorText = styled.p`
  color: ${COLORS.color_danger};
  font-size: 12px;
  margin-left: 20px;
  margin-top: 4px;
  margin-bottom: 0;
`;

export default ErrorText;
