import styled from "styled-components";
import { COLORS } from "../constants";

const Label = styled.label`
  color: ${COLORS.color_neutral_darkest};
  font-size: 12px;
  margin-left: 20px;
  margin-bottom: 4px;
`;

export default Label;
