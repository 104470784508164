import { COLORS } from '../../constants'

const segfyTheme = (theme) => ({
  ...theme,
  colors: {
    ...theme.colors,
    primary25: COLORS.color_neutral_light,
    primary: COLORS.color_neutral_darkest
  }
})

const originalControlCustomStyles = {
  minWidth: 250,
  height: 30,
  minHeight: 30,
  fontSize: 12,
  borderRadius: 18,
  borderColor: COLORS.color_neutral_medium,
  ValueContainer: {}
}

const originalCustomStyles = {
  indicatorsContainer: (provided, state) => ({
    ...provided,
    height: 30
  }),
  valueContainer: (provided, state) => ({
    ...provided,
    height: 30,
    position: 'initial'
  }),
  control: (base) => ({
    ...base,
    ...originalControlCustomStyles
  }),
  menu: (provided, state) => ({
    ...provided,
    fontSize: 12
  })
}

const customStylesDanger = {
  ...originalCustomStyles,
  control: (base) => ({
    ...base,
    ...originalControlCustomStyles,
    borderColor: COLORS.color_danger
  })
}

const customStylesDefault = {
  ...originalCustomStyles,
  control: (base) => ({
    ...base,
    ...originalControlCustomStyles
  })
}

export { segfyTheme, customStylesDefault, customStylesDanger }
