import styled, { css } from 'styled-components'
import { COLORS } from "../../constants";

export const BadgeIconButton = styled.button`
  border: none;
  outline: none;
  display: flex;
  align-items: flex-end;
  cursor: pointer;
  color: ${(props) => (props.hasNotification ? COLORS.color_gold : COLORS.color_neutral_dark)};
  background-color: transparent;
`;

export const Badge = styled.span`
  display: ${(props) => (props.hasNotification ? 'flex' : 'none')};
  position: absolute;
  margin: 10px;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: ${COLORS.color_gold};
  color: #ffffff;
  color: #FFFFFF;
  opacity: 1;
  font-size: 9pt;
  align-items: center;
  justify-content: center;
`;

export const Popover = styled.div`
  display: ${(props) => (props.open ? '' : 'none')};
  position: absolute;
  z-index: 1030;
  width: 500px;
  height: 400px;
  top: ${(props) => (props.position ? props.position.top : 0)}px;
  left:  ${(props) => (props.position ? props.position.left : 0)}px;
`

export const PopoverArrow = styled.div`
display: block;
height: 10px;
width: 10px;
position: absolute;
background-color: ${COLORS.color_lightest};
margin: 0;
padding: 0;

${(props) =>
    props.placement == 'bottom' &&
    css`
    top: -5px;
    left: 50%;
    transform: translateX(-50%) rotate(45deg);
    border-color: ${COLORS.color_lightest} transparent transparent ${COLORS.color_lightest};
  `}
`

export const PopoverTitle = styled.div`
  padding: 10px;
  font-size: 19px;
  color: ${COLORS.color_neutral_dark};
  letter-spacing: 0px;
  font-weight: 600;
`

export const PopoverInner = styled.div`
  background-clip: padding-box;
  background-color: ${COLORS.color_lightest};
  border-radius: 2px;
  box-shadow: 0 3px 6px -4px rgb(0 0 0 / 12%), 0 6px 16px 0 rgb(0 0 0 / 8%),
    0 9px 28px 8px rgb(0 0 0 / 8%);
`

export const PopoverInnerContent = styled.div`
  color: rgba(0, 0, 0, 0.85);
  padding: 10px;
`

export const PopoverClose = styled.button`
  margin: 10px 10px 0px 0px;
  border: none;
  outline: none;
  display: flex;
  align-items: flex-end;
  cursor: pointer;
  color: ${COLORS.color_danger};
  background-color: transparent;
  float: right;
`;

export const ItemContainer = styled.div`
  display: flex;
  padding: 10px;
  position: relative;
`;

export const NotificationText = styled.div`
  display: flex;
  padding: 10px 10px 10px 0px;
  width: 70%;
  font-size: 14px;
`;

export const ItemPrimary = styled.div`
  background-color: ${COLORS.color_neutral_lightest};
  text-align: center;
  display: flex;
  width: 100%; 
`;

export const ItemSecondary = styled.div`
  background-color: ${COLORS.color_lightest};
  text-align: center;
  display: flex;
  width: 100%;  
`;

export const OptionsContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 20%;
`;

export const DeleteIconButton = styled.button`
  border: none;
  outline: none;
  display: flex;
  align-items: flex-end;
  cursor: pointer;
  color: ${COLORS.color_danger};
  background-color: transparent;
`;


export const MarkReadButton = styled.button`
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
  float: right;
  color: ${COLORS.color_danger};
  text-decoration: underline ${COLORS.color_danger}; 
  font-size: 13px;
`;

export const ButtonContainer = styled.div`
  padding: 10px;
  margin-bottom: 10px;
`;

export const RemoveAllButton = styled.button`
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
  float: right;
  color: ${COLORS.color_neutral_dark};
  text-decoration: underline ${COLORS.color_neutral_dark}; 
  font-size: 13px;
`;

export const CheckboxRounded = styled.input`
  width: 1.3em;
  height: 1.3em;
  background-color: ${COLORS.color_lightest};
  border-radius: 50%;
  vertical-align: middle;
  border: 2px solid ${COLORS.color_neutral_lightest}; 
  appearance: none;
  outline: none;
  cursor: pointer;

  &:checked {
    background-color: ${COLORS.color_danger_orange};
  }
`;

export const HighlightContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${COLORS.color_gold};
  padding-left: 5px;
  width: 5%;
`;
