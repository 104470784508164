import styled from "styled-components";

export const Container = styled.div`
  width: 25px;
  height: 25px;
`;

export const Circle = styled.div`
  width: 17px;
  height: 17px;
  background: var(--segfy-color-white);
  box-shadow: 0px 1px 2px var(--segfy-color-shadow-black);
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;

  i {
    top: 2px !important;
  }
`;

export const Arrow = styled.div`
  transition: 0.2s;
  transition-property: transform;
  transform-origin: 50% 50%;

  margin: ${({ direction }) => {
    switch (direction) {
      case "down":
        return "0 0 -2px 0";
      case "up":
        return "-2px 0 0 0";
      case "left":
        return "0 0 2px -2px";
      case "right":
        return "2px -4px 0 0";
    }
  }};

  transform: ${({ direction }) => {
    switch (direction) {
      case "down":
        return "rotate(0deg)";
      case "up":
        return "rotate(180deg)";
      case "left":
        return "rotate(90deg)";
      case "right":
        return "rotate(270deg)";
    }
  }};


`;
