import styled, { css } from 'styled-components'
import { COLORS } from '../../constants'

export const Button = styled.button`
  border: none;
  outline: 0px;
  cursor: pointer;
  padding: 6px;
  min-width: 80px;
  border-radius: 50%;

  display: flex;
  justify-content: center;
  align-items: flex-end;

  color: ${COLORS.color_brand_primary_medium};
  background-color: transparent;

  transition: color 0.1s ease-in-out;

  ${(props) =>
    props.primary &&
    css`
      background-color: ${COLORS.color_brand_primary_medium};
      color: ${COLORS.color_lightest};
    `}

  ${(props) =>
    props.disabled &&
    css`
      color: ${COLORS.color_neutral_darkest};
      cursor: not-allowed;
    `}

  span:first-of-type {
    margin-right: ${(props) => (props.margin ? `${props.margin}px` : '12px')};
  }

  ${(props) =>
    props.vertical &&
    css`
      flex-direction: column;
      align-items: center;
      justify-content: space-between;

      span:first-of-type {
        margin-right: 0;
        margin-bottom: ${(props) =>
          props.margin ? `${props.margin}px` : '10px'};
      }
    `}

  &:hover {
    color: ${COLORS.color_brand_primary_light};
  }
`
export const Link = styled.span`
  text-decoration: underline;
`
