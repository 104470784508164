import React, { useEffect } from 'react'
import PropTypes from 'prop-types';
import {
  DeleteIconButton,
  Popover,
  PopoverClose,
  PopoverArrow,
  PopoverInner,
  PopoverInnerContent,
  PopoverMessage,
  PopoverMessageButtons,
  PopoverMessageTitle
} from './styles'
import FontIcon from '../FontIcon'

import Button from "../../controls/button";

const PopConfirm = (props) => {
  const [isOpen, setIsOpen] = React.useState(false);
  const [alreadyOpened, setAlreadyOpened] = React.useState(false);
  const [popoverPos, setPopoverPos] = React.useState(null);
  const [placement, setPlacement] = React.useState(props.placement);
  const popoverBtnRef = React.useRef(null);
  const popoverRef = React.useRef(null);
  const [popoverButton, setPopoverButton] = React.useState({ top: 0, left: 0 });

  useEffect(() => {
    if (isOpen && !alreadyOpened) {
      setIsOpen(false);
      setAlreadyOpened(true);
      SetPopoverPosition();
      setIsOpen(true);
    }

    if (popoverBtnRef.current && props && !props.posFromViewport)
      setPopoverButton({ top: popoverBtnRef.current.offsetTop, left: popoverBtnRef.current.offsetLeft });
    else
      setPopoverButton(popoverBtnRef.current.getBoundingClientRect())

    if (popoverRef.current && isOpen) {
      SetPopoverPosition();
    }
  }, [isOpen]);

  useEffect(() => {
    function handleResize() {
      if (popoverBtnRef.current && popoverRef.current) {
        if (props && !props.posFromViewport)
          setPopoverButton({ top: popoverBtnRef.current.offsetTop, left: popoverBtnRef.current.offsetLeft });
        else
          setPopoverButton(popoverBtnRef.current.getBoundingClientRect())

        SetPopoverPosition();
      }
    }
    window.addEventListener('resize', handleResize)

    return () => window.removeEventListener('resize', handleResize);
  });

  return (
    <div>
      <DeleteIconButton ref={popoverBtnRef} onClick={() => setIsOpen(!isOpen)} type='button' id='btnPopover' >
        <FontIcon size={14} icon='trash' />
      </DeleteIconButton>

      <Popover ref={popoverRef} open={isOpen} id='popover' position={popoverPos}>
        <PopoverClose type='button' onClick={() => setIsOpen(false)}>
          <FontIcon size={8} icon='cancel' />
        </PopoverClose>
        <PopoverArrow placement={placement} />
        <PopoverInner>
          <PopoverInnerContent>
            <PopoverMessage>
              <PopoverMessageTitle>{props.text}</PopoverMessageTitle>
            </PopoverMessage>
            <PopoverMessageButtons>
              <Button xsmall primary type='button' onClick={() => setIsOpen(false)}>
                <span>Cancelar</span>
              </Button>
              <Button xsmall type='button' onClick={() => { setIsOpen(false); props.onConfirm() }}>
                <span>Deletar</span>
              </Button>
            </PopoverMessageButtons>
          </PopoverInnerContent>
        </PopoverInner>
      </Popover>
    </div >
  )

  function SetPopoverPosition() {
    const settingPopoverPosition = {
      'top': function () {
        return checkPositionToTop();
      },
      'right': function () {
        return checkPositionToRight();
      },
      'left': function () {
        return checkPositionToLeft();
      },
      'bottom': function () {
        return checkPositionToBottom();
      },
    };

    if (placement && popoverButton) {
      settingPopoverPosition[placement]();
    }
  }

  function checkPositionToTop() {
    const popoverElement = popoverRef.current.getBoundingClientRect();

    if (((popoverButton.top - popoverElement.height - 20) < 0
      || (popoverButton.left + (popoverElement.width / 2) > window.innerWidth)
      || (popoverButton.left - (popoverElement.width / 2) < 0)) && props.reposition)
      popoverReposition('top')
    else {
      const newPopoverPosition = {};
      newPopoverPosition.top = popoverButton.top - popoverElement.height - 20;
      newPopoverPosition.left = popoverButton.left - (popoverElement.width / 2) + (popoverButton.width / 2);
      setPopoverPos(newPopoverPosition);

    }
  }

  function checkPositionToRight() {
    const popoverElement = popoverRef.current.getBoundingClientRect();

    if ((popoverButton.left + (popoverElement.width / 2)) > window.innerWidth && props.reposition)
      popoverReposition('right')
    else {
      const newPopoverPosition = {};
      newPopoverPosition.top = popoverButton.top - (popoverElement.height / 2) + 10;
      newPopoverPosition.left = popoverButton.left + 40;
      setPopoverPos(newPopoverPosition);
    }
  }

  function checkPositionToLeft() {
    const popoverElement = popoverRef.current.getBoundingClientRect();

    if ((popoverButton.left - popoverElement.width - 20) < 0 && props.reposition)
      popoverReposition('left')
    else {
      const newPopoverPosition = {};
      newPopoverPosition.top = popoverButton.top - (popoverElement.height / 2) + 10;
      newPopoverPosition.left = popoverButton.left - popoverElement.width - 20;
      setPopoverPos(newPopoverPosition);
    }
  }

  function checkPositionToBottom() {
    const popoverElement = popoverRef.current.getBoundingClientRect();

    if (((popoverButton.top + popoverElement.height) > window.innerHeight
      || (popoverButton.left + (popoverElement.width / 2) > window.innerWidth)
      || (popoverButton.left - (popoverElement.width / 2) < 0)) && props.reposition)
      popoverReposition('bottom')
    else {
      const newPopoverPosition = {};
      newPopoverPosition.top = popoverButton.top + 40;
      newPopoverPosition.left = popoverButton.left - (popoverElement.width / 2) + (popoverButton.width / 2);
      setPopoverPos(newPopoverPosition);
    }
  }

  function popoverReposition(currentPosition) {

    const settingNewPopoverPosition = {
      'top': function () {
        setPlacement('right');
        return checkPositionToRight();
      },
      'right': function () {
        setPlacement('bottom');
        return checkPositionToBottom();
      },
      'bottom': function () {
        setPlacement('left');
        return checkPositionToLeft();
      },
      'left': function () {
        setPlacement('top');
        return checkPositionToTop();
      },
    };

    if (currentPosition) {
      settingNewPopoverPosition[currentPosition]();
    }
  }
}

PopConfirm.propTypes = {
  text: PropTypes.string,
  onConfirm: PropTypes.func.isRequired,
  placement: PropTypes.string,
  isOpen: PropTypes.bool,
  reposition: PropTypes.bool,
  posFromViewport: PropTypes.bool,
};

PopConfirm.defaultProps = {
  reposition: true,
  placement: 'right',
  text: 'Você tem certeza?',
  posFromViewport: false
}

export { PopConfirm };
