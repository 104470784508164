const COLORS = {
    color_darkest: "#48413C",
    color_lightest: "#ffffff",
    color_danger: '#d25032',
    color_brand_primary_medium: "#d25032",
    color_brand_primary_light: "#f27c61",
    color_brand_primary_lightest: "#FCAC9A",
    color_brand_primary_ultra_lightest: "#F5E2DE",
  
    color_neutral_lightest: "#faf7f2",
    color_neutral_light: "#f1f3f4",
    color_neutral_medium: "#dedede",
    color_neutral_dark: '#b1aba6',
    color_neutral_darkest: '#807973',
    color_green: '#69c782',
    color_green_lightest: '#69c7826e',
    color_lime: '#E2ED8D',
    color_orange: '#EFBE7A',
    color_danger_orange: '#F2937E',
    color_gold: '#F4AD4B'
  };
  
  const BUTTON_SIZES = {
    default: { width: "309px", height: "30px" },
    medium: { width: "200px", height: "30px" },
    small: { width: "115px", height: "30px" },
    xsmall: { width: "90px", height: "30px" },
  };
  
  const DEVICES_SIZES = {
    min_phone: '320px',
    phone: '640px',
  
    min_tablet: '641px',
    tablet: '768px',
  
    min_desktop: '769px',
    desktop: '7680px'
  }
  
  const DEVICE = {
    phone: `(min-width: ${DEVICES_SIZES.min_phone}) and (max-width: ${DEVICES_SIZES.phone})`,
    tablet: `(min-width: ${DEVICES_SIZES.min_tablet}) and (max-width: ${DEVICES_SIZES.tablet})`,
    desktop: `(min-width: ${DEVICES_SIZES.min_desktop}) and (max-width: ${DEVICES_SIZES.desktop})`,
  };
  
  export { COLORS, BUTTON_SIZES, DEVICE };
  