import styled, { css } from "styled-components";
import { GridBase } from "../../shared-styles";
import { TextFieldCss } from "../../controls/text-field";
import { COLORS, DEVICE } from "../../constants";

export const InputContainer = styled(GridBase)`

  & label {
    color: ${COLORS.color_neutral_darkest};
    font-size: 12px;
    margin-left: 20px;
    margin-bottom: 4px;
  }

  & input {
    box-sizing: border-box;
    flex-grow: 1;
    ${TextFieldCss}

    @media ${DEVICE.phone} {
      margin: 1em .8em;
    }

    ${(props) =>
      props.big_field &&
      css`
        @media ${DEVICE.tablet} {
          min-width: 350px;
        }

        @media ${DEVICE.desktop} {
          min-width: 350px;
        }
      `};
  }

  & input.has-error {
    border-color: red;
  }

  & textarea {
    border-radius: 2px;
    padding: 10px;
    border: 1px solid ${COLORS.color_neutral_medium};
    min-height: 100px;

    :focus {
      border: 1px solid ${COLORS.color_neutral_darkest};
      transition: ease-in-out, width 0.35s ease-in-out;
      outline: none;
    }
  }
`;
