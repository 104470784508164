import styled, { css } from "styled-components";
import { COLORS } from "../../constants";

const BtnGroup = styled.button`
  width: 81px;
  height: 28px;
  letter-spacing: 0.5px;
  background-color: ${COLORS.color_lightest};
  color: ${COLORS.color_neutral_darkest};
  border: 1px solid ${COLORS.color_danger};
  cursor: pointer;
  outline:none;

  ${(props) =>
    props.active &&
    css`
      background-color: ${COLORS.color_brand_primary_ultra_lightest};
      color: ${COLORS.color_brand_primary_medium};
      font-weight: bold;
      border: 1px solid ${COLORS.color_danger};
  `};

  ${(props) =>
    props.first &&
    css`
      border-radius: 14px 0px 0px 14px;
  `};

  ${(props) =>
    props.last &&
    css`
      border-radius: 0px 15px 15px 0px;
  `};

  ${(props) =>
    !props.last &&
    css`
      border-right: none;
  `};
    
`;

export default BtnGroup;
