import React from "react";
import PropTypes from 'prop-types';
import FontIcon from "../FontIcon";
import Button from "./styles";

const IconButton = (props) => {
  return (
    <Button type="button" {...props}>
      <FontIcon icon={props.icon} circleDiameter={props.circleDiameter} size={props.icon_size}/>
    </Button>
  );
};

IconButton.propTypes = {
  icon: PropTypes.string.isRequired,
  circleDiameter: PropTypes.number,
  circle: PropTypes.string,
};

IconButton.defaultProps = {
  icon_size: 15,
};

export default IconButton;
