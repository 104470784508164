/* eslint-disable no-unused-vars */
import React from 'react'
import ReactDatePicker, { registerLocale } from 'react-datepicker'
import ptbr from 'date-fns/locale/pt-BR' // the locale you want
import { TextField } from '../../controls/text-field'
import 'react-datepicker/dist/react-datepicker.css'
import InputMask from 'react-input-mask'
import { DatePickerContainer } from './styles'

registerLocale('pt-BR', ptbr)

const DatePickerContainerElement = ({ children }) => {
  return (
    <div className='datepicker-container'>
      <div style={{ position: 'relative' }}>{children}</div>
    </div>
  )
}

const DatePicker = ({ onChange, value }) => {
  return (
    <DatePickerContainer>
      <ReactDatePicker
        popperModifiers={{
          offset: {
            enabled: true,
            offset: '5px, 10px'
          },
          preventOverflow: {
            enabled: true,
            escapeWithReference: false, // force popper to stay in viewport (even when input is scrolled out of view)
            boundariesElement: 'viewport'
          }
        }}
        locale='pt-BR'
        placeholderText='dd/mm/aaaa'
        dateFormat='dd/MM/yyyy'
        showTimeSelect={false}
        customInput={
          <InputMask mask='99/99/9999' alwaysShowMask={true}>
            {(inputProps) => <TextField></TextField>}
          </InputMask>
        }
        dropdownMode='select'
        calendarContainer={DatePickerContainerElement}
        selected={Date.parse(parseDateISOString(value))}
        onChange={onChange}
      />
    </DatePickerContainer>
  )
}

const parseDateISOString = (s) => {
  if (!s) return null

  if (s instanceof Date) return s

  let ds = s.split(/\D+/).map((s) => parseInt(s))
  ds[1] = ds[1] - 1 // adjust month
  return new Date(...ds)
}

export { DatePickerContainer, DatePickerContainerElement, DatePicker }
