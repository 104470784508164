import React from 'react'
import IconButtonContainer from './styles'

const FontIcon = ({ icon, size }) => {
  return (
    <IconButtonContainer>
      <i
        className={`icon-pipe-${icon}`}
        style={{
          fontSize: `${size}pt`,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center'
        }}
      ></i>
    </IconButtonContainer>
  )
}

FontIcon.defaultProps = {
  size: 15
}

export default FontIcon
