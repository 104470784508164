import styled from 'styled-components'
import { COLORS } from '../../constants'
import { DEVICE } from '../../constants'

export const PanelContainer = styled.div`
  background-color: ${COLORS.color_lightest};
  padding: 1em;
  border-radius: 6px;
  min-height: 200px;

  @media ${DEVICE.phone} {
    padding: 0.5em;
  }

  & h2,
  & h3 {
    color: ${COLORS.color_neutral_darkest};
    margin-top: 0;
  }
`
