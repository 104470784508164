import styled, { css } from "styled-components";
import { COLORS } from "../../constants";

export const InsuredStatus = styled.div`
  width: 100px;
  height: 16px;
  border-radius: 8px;
  opacity: 1;
  text-align: center;

  color: ${COLORS.color_lightest};
  font-size: 11px;
  letter-spacing: 0px;

  ${(props) =>
        props.label && props.label === 'Prospecção' &&
        css`
        background-color: ${COLORS.color_lime};
        color: ${COLORS.color_neutral_darkest};
  `};

  ${(props) =>
        props.label && props.label === 'Ativo' &&
        css`
        background-color: ${COLORS.color_green};
        color: ${COLORS.color_lightest};
`};

  ${(props) =>
        props.label && props.label === 'Inativo' &&
        css`
        background-color: ${COLORS.color_neutral_light};
        color: ${COLORS.color_neutral_dark};
  `};

  ${(props) =>
        props.label && props.label === 'Orçamento' &&
        css`
        background-color: ${COLORS.color_orange};
        color: ${COLORS.color_lightest};
  `};

  ${(props) =>
        props.label && props.label === 'Em aprovação' &&
        css`
        background-color: ${COLORS.color_danger_orange};
        color: ${COLORS.color_lightest};
  `};
`;
