import styled from 'styled-components';

export const Container = styled.div`
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100vw;
  height: 100%;
  background: black;
  z-index: 5;
  opacity: 0.8;
`;

export const SpinnerContainer = styled.div`
  position: fixed;
  left: calc(50% - 50px);
  top: calc(50% - 55px);
  opacity: 1;
  z-index: 6;
`;
