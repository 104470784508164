import styled from "styled-components";
import { DEVICE } from "../constants";

const FixedBottonMobile = styled.div`
  @media ${DEVICE.phone} {
    position: fixed;
    min-width: 100%;
    left: 0;
    bottom: 15px;
    z-index: 15000;
    display: flex;
    justify-content: center;
  }
`;

export { FixedBottonMobile };
