import styled from 'styled-components'
import { DEVICE, COLORS } from '../../constants'

export const Heading1 = styled.h1`
  color: ${(props) =>
    props.color ? COLORS[props.color] : COLORS.color_darkest};
  margin: 0;
  @media ${DEVICE.phone} {
    font-size: 1.35em;
  }

  @media ${DEVICE.tablet} {
    font-size: 1.5em;
  }

  font-size: 1.8em;
`

export const Heading2 = styled.h2`
  color: ${(props) =>
    props.color ? COLORS[props.color] : COLORS.color_darkest};
  margin: 0;
  @media ${DEVICE.phone} {
    font-size: 0.9em;
  }

  @media ${DEVICE.tablet} {
    font-size: 0.95em;
  }
  font-size: 1.1em;
`

export const Heading3 = styled.h3`
  color: ${(props) =>
    props.color ? COLORS[props.color] : COLORS.color_darkest};
  margin: 0;
  @media ${DEVICE.phone} {
    font-size: 0.8em;
  }

  @media ${DEVICE.tablet} {
    font-size: 0.85em;
  }
  font-size: 0.95em;
`
