import styled, { css } from 'styled-components'
import { FlexBase } from '../../../shared-styles'
import { DEVICE } from '../../../constants'

const Flex = styled(FlexBase)`
  flex-wrap: wrap;
  ${(props) =>
    props.align_right &&
    css`
      justify-content: flex-end;
    `};

  ${(props) =>
    props.vertical_centered &&
    css`
      align-items: center;
    `};
  ${(props) =>
    props.align_left &&
    css`
      justify-content: flex-start;
    `};

  ${(props) =>
    props.space_between &&
    css`
      justify-content: space-between;
    `};
`

const VerticalFlex = styled(FlexBase)`
  flex-direction: column;
`

const HorizontalFlexMobile = styled(FlexBase)`
  flex-direction: row;
  justify-content: space-between;
`

const HorizontalFlex = styled(FlexBase)`
  @media ${DEVICE.phone} {
    align-items: initial;
    flex-direction: column;
  }

  @media ${DEVICE.tablet} {
    align-items: ${(props) =>
      props.align_items ? props.align_items : "center"};
    flex-direction: row;
  }

  @media ${DEVICE.desktop} {
    align-items: ${(props) =>
      props.align_items ? props.align_items : "center"};
    flex-direction: row;
  }
`;

const SpaceBetween = styled(FlexBase)`
  align-items: center;
  justify-content: space-between;

  align-items: ${(props) => (props.align_vertical_end ? 'flex-end' : 'center')};
`

const FlexItem = styled.div`
  margin-right: ${(props) => (props.margin_right ? props.margin_right : '')};
  margin-left: ${(props) => (props.margin_left ? props.margin_left : '')};
  margin-top: ${(props) => (props.margin_top ? props.margin_top : '')};
  margin-bottom: ${(props) => (props.margin_bottom ? props.margin_bottom : '')};
`

const ItemSpan1 = styled.div`
  grid-column: span 1;
  display: flex;
  flex-direction: column;
`

const ItemSpan2 = styled.div`
  @media ${DEVICE.phone} {
    grid-column: span 1;
  }

  @media ${DEVICE.tablet} {
    grid-column: span 2;
  }

  @media ${DEVICE.desktop} {
    grid-column: span 2;
  }
`

const ItemSpan3 = styled.div`
  @media ${DEVICE.phone} {
    grid-column: span 1;
  }
  @media ${DEVICE.tablet} {
    grid-column: span 2;
  }

  @media ${DEVICE.desktop} {
    grid-column: span 3;
  }
`

export {
  VerticalFlex,
  Flex,
  HorizontalFlexMobile,
  HorizontalFlex,
  SpaceBetween,
  FlexItem,
  ItemSpan1,
  ItemSpan2,
  ItemSpan3
}
