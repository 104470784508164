import styled, { css } from 'styled-components'
import { COLORS } from '../../constants'

const Button = styled.button`
  height: ${(props) => (props.height ? props.height : '40px')};
  width: ${(props) => (props.width ? props.width : '40px')};
  border-radius: 50%;

  cursor: pointer;
  border: none;
  outline: 0px;

  transition-duration: 0.2s;

  background-color: ${COLORS.color_brand_primary_medium};
  color: ${COLORS.color_lightest};

  ${(props) =>
    props.margin &&
    css`
      margin-left: ${props.margin}px;
      margin-right: ${props.margin}px;
    `};

  ${(props) =>
    props.transparent_background &&
    css`
      background-color: transparent;
    `};

  ${(props) =>
    props.primary &&
    css`
      background-color: ${COLORS.color_lightest};
      color: ${COLORS.color_brand_primary_medium};
    `};

  ${(props) =>
    props.circle &&
    css`
      width: ${(props) =>
        props.circleDiameter ? `${props.circleDiameter}px` : '30px'};
      height: ${(props) =>
        props.circleDiameter ? `${props.circleDiameter}px` : '30px'};
      text-align: center;
      border-radius: 50%;
      color: ${COLORS.color_lightest};
      background-color: ${COLORS.color_brand_primary_medium};
      display: flex;
      justify-content: center;
      align-items: center;
    `};

  ${(props) =>
    props.normal &&
    css`
      background-color: ${COLORS.color_lightest};
      color: ${COLORS.color_neutral_darkest};
    `};

  ${(props) =>
    props.disabled &&
    css`
      background-color: ${COLORS.color_lightest};
      cursor: not-allowed;
      color: ${COLORS.color_neutral_dark};
    `};
`

export default Button
