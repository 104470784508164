import React from "react";
import { PanelContainer } from "./styles";

const Panel = (props) => {
  return (
    <PanelContainer>
      {props.children}
    </PanelContainer>
  );
};

export { Panel };
