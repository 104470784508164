import styled, { css } from 'styled-components'
import { COLORS } from "../../constants";

export const DeleteIconButton = styled.button`
  border: none;
  outline: none;
  display: flex;
  align-items: flex-end;
  cursor: pointer;
  color: ${COLORS.color_danger};
  background-color: transparent;
`;

export const Popover = styled.div`
  display: ${(props) => (props.open ? '' : 'none')};
  position: absolute;
  z-index: 1030;
  width: 230px;
  top: ${(props) => (props.position ? props.position.top : 0)}px;
  left:  ${(props) => (props.position ? props.position.left : 0)}px;
`

export const PopoverArrow = styled.div`
display: block;
height: 10px;
width: 10px;
position: absolute;
background-color: ${COLORS.color_lightest};
margin: 0;
padding: 0;

${(props) =>
    props.placement == 'top' &&
    css`
    bottom: -5px;
    left: 50%;
    transform: translateX(-50%) rotate(45deg);
    border-color: transparent ${COLORS.color_lightest} ${COLORS.color_lightest} transparent;
`}

${(props) =>
    props.placement == 'right' &&
    css`
      top: 50%;
      left: -5px;
      transform: translateY(-50%) rotate(45deg);
      border-color: transparent transparent ${COLORS.color_lightest} ${COLORS.color_lightest};
  `}

  ${(props) =>
    props.placement == 'left' &&
    css`
    right: -5px;
    top: 50%;
    transform: translateY(-50%) rotate(45deg);
    border-color: transparent transparent ${COLORS.color_lightest} ${COLORS.color_lightest};
  `}

  ${(props) =>
    props.placement == 'bottom' &&
    css`
    top: -5px;
    left: 50%;
    transform: translateX(-50%) rotate(45deg);
    border-color: ${COLORS.color_lightest} transparent transparent ${COLORS.color_lightest};
  `}
`

export const PopoverInner = styled.div`
  background-clip: padding-box;
  background-color: ${COLORS.color_lightest};
  border-radius: 2px;
  box-shadow: 0 3px 6px -4px rgb(0 0 0 / 12%), 0 6px 16px 0 rgb(0 0 0 / 8%),
    0 9px 28px 8px rgb(0 0 0 / 8%);
`

export const PopoverMessage = styled.div`
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  padding: 4px 0 12px;
  position: relative;
`

export const PopoverMessageTitle = styled.div`
  padding-left: 22px;
`

export const PopoverMessageButtons = styled.div`
  margin-bottom: 4px;
  text-align: right;
  display: flex;
  align-content: space-between;
`

export const PopoverInnerContent = styled.div`
  color: rgba(0, 0, 0, 0.85);
  padding: 12px 16px;
`

export const PopoverClose = styled.button`
  margin: 5px 5px 0px 0px;
  border: none;
  outline: none;
  display: flex;
  align-items: flex-end;
  cursor: pointer;
  color: ${COLORS.color_danger};
  background-color: transparent;
  float: right;
`;
