import styled from "styled-components";
import { DEVICE } from "../../constants";

export const SearchIconWrapper = styled.div`
  display: flex;
  position: relative;

  & input{
    @media ${DEVICE.phone} {
      box-shadow: 0.1px 3px 6px rgb(0 0 0 / 20%);
    }
    
  }

  :before {
    content: "";
    position: absolute;
    right: 10px;
    @media ${DEVICE.phone} {
      right: 20px;
    }
    top: 0;
    bottom: 0;
    width: 20px;
    background: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' version='1.1' x='0px' y='0px' width='25px' height='25px' viewBox='0 0 25 25' style='enable-background:new 0 0 25 25;' xml:space='preserve'><g id='Icons' style='opacity:0.40;'><path id='search-big' d='M18.853,17.438l-3.604-3.604c-0.075-0.075-0.166-0.127-0.267-0.156C15.621,12.781,16,11.686,16,10.5   C16,7.463,13.537,5,10.5,5S5,7.463,5,10.5S7.463,16,10.5,16c1.186,0,2.281-0.379,3.18-1.018c0.028,0.101,0.08,0.191,0.155,0.267   l3.604,3.604c0.301,0.301,0.858,0.227,1.249-0.165C19.079,18.297,19.153,17.739,18.853,17.438z M10.5,14C8.568,14,7,12.432,7,10.5   S8.568,7,10.5,7S14,8.568,14,10.5S12.432,14,10.5,14z'/></g><g id='Guides'></g></svg>")
      center / contain no-repeat;
  }
`;
